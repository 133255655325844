<template>
  <v-container fluid class="pa-0">

    <!-- App bar -->
    <v-app-bar height="30" dark color="primary" flat>
      <div class="mx-auto" style="font-size: 20px; user-select: none">Mnemonist Software</div>
    </v-app-bar>

    <!-- Loading -->
    <div v-if="loading" class="pa-6">
      {{ $t('common.loading') }}
    </div>

    <!-- Header -->
    <div v-if="!loading" style="width: 100%"
         class="pa-2 d-flex flex-row flex-nowrap justify-space-between align-start">
      <div id="phase">{{ $t('group.phase') + ': ' + phase }}</div>
      <v-btn color="primary" class="ma-2 mr-3" @click="deleteGroup()">{{ $t('group.delete-group') }}</v-btn>
    </div>

    <!-- Standard preview-->
    <div v-if="!loading" style="width: 100%" class="d-flex flex-row flex-wrap justify-center align-center">
      <span v-for="(user, index) in users" :key="user.uid" @click="preview = index" class="standard-preview-container">
        <GamePreview :event="user.event" :type="'standard'" :memo="user.memo" :recall="user.recall" :order="user.order"
                     :highlight="(step === 'memo' && user.time === 0) || (step === 'recall' && user.time < 0) ? user.highlight : []"
                     :step="step" :name="user.name" :time="user.time"/>
      </span>
    </div>

    <!-- Fullscreen preview -->
    <span @click="preview = -1">
    <GamePreview :order="fullscreen.order" :recall="fullscreen.recall" :memo="fullscreen.memo" :step="step"
                 :event="fullscreen.event" :type="fullscreen.type" :name="fullscreen.name" :time="fullscreen.time"
                 :highlight="(step === 'memo' && fullscreen.time === 0) || (step === 'recall' && fullscreen.time < 0) ? fullscreen.highlight : []"/>
    </span>

  </v-container>
</template>

<script>
import GamePreview from '../components/GamePreview';

export default {
  name: 'GroupPreview',
  components: {GamePreview},
  data: () => ({
    users: [], group: {}, loading: true, preview: -1,
    updateInterval: null, insertInterval: null, inserts: [],
    nowInterval: null, now: -1
  }),
  computed: {
    fullscreen() {
      if (this.loading || this.preview === -1) return ({
        type: 'none', event: '', name: '', score: 0, time: 0,
        memo: [], recall: [], order: [], highlight: []
      });
      let user = this.users[this.preview];
      return ({
        type: 'fullscreen', event: user.event, name: user.name, score: user.score, time: user.time,
        memo: user.memo, recall: user.recall, order: user.order, highlight: user.highlight
      });
    },
    games() {
      return this.users.map(user => user.gid);
    },
    phase() {
      if (this.now === -1)
        return this.$t('group.loading');
      if (this.now < this.group.started)
        return this.$t('group.waiting') + this.parseTime(this.group.started - this.now);
      else if (this.now < this.group.started + 1000 * this.group.memo)
        return this.$t('group.memo') + this.parseTime(this.group.started + 1000 * this.group.memo - this.now);
      else if (this.now < this.group.started + 1000 * this.group.memo + 10000)
        return this.$t('group.waiting') + this.parseTime(this.group.started + 1000 * this.group.memo + 10000 - this.now);
      else if (this.now < this.group.started + 1000 * this.group.memo + 10000 + 1000 * this.group.recall)
        return this.$t('group.recall') + this.parseTime(this.group.started + 1000 * this.group.memo + 10000 + 1000 * this.group.recall - this.now);
      else return this.$t('group.end');
    },
    step() {
      if (this.now === -1) return 'memo-loading';
      else if (this.now < this.group.started) return 'memo-before';
      else if (this.now < this.group.started + 1000 * this.group.memo) return 'memo';
      else if (this.now < this.group.started + 1000 * this.group.memo + 10000) return 'memo-after';
      else if (this.now < this.group.started + 1000 * this.group.memo + 10000 + 1000 * this.group.recall) return 'recall';
      else return 'end';
    }
  },
  mounted() {
    this.$api.getMyGroup().then((data) => {
      this.users = data.users;
      this.group = data.group;
      this.group.started = parseInt(this.group.started);
      let defaultRecall = new Array(this.group.number).fill('');
      for (let user of this.users) {
        if (user.recall.length !== this.group.number) user.recall = defaultRecall;
        user.memo = user.data;
        delete user.data;
        user.highlight = [];
      }
      this.updateInterval = setTimeout(this.update, 2000);
      this.insertInterval = setTimeout(this.insert, 30);
    }).catch(() => {
      this.$store.commit('showSnackbar', this.$t('common.error'));
    }).finally(() => {
      this.loading = false;
    });
    this.nowInterval = setTimeout(this.timelapse, 100);
  },
  destroyed() {
    if (this.updateInterval != null) clearTimeout(this.updateInterval);
    if (this.insertInterval != null) clearTimeout(this.insertInterval);
    if (this.nowInterval != null) clearTimeout(this.nowInterval);
  },
  methods: {
    deleteGroup() {
      this.$api.deleteGroup().then(() => {
        this.$router.push('/group-my');
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      });
    },
    insert() {
      let limit = new Date().getTime() - 4000;
      while (this.inserts.length > 0) {
        let insert = this.inserts[0];
        if (insert.timestamp <= limit) {
          let userIndex = this.users.findIndex((user) => user.gid === insert.gid);
          this.users[userIndex].highlight = insert.data.highlight;
          this.users[userIndex].time = insert.data.time;
          this.users[userIndex].recall = insert.data.recall;
          this.inserts.shift();
        } else break;
      }
      this.insertInterval = setTimeout(this.insert, 30);
    },
    parseTime(time) {
      let m = Math.floor(time / 60000);
      let s = Math.floor((time - m * 60000) / 1000);
      return ' (' + m + ':' + (s < 10 ? '0' : '') + s + ')';
    },
    update() {
      let timestamp = this.inserts.length === 0 ? 0 : parseInt(this.inserts[this.inserts.length - 1].timestamp);
      this.$api.liveGet(this.games, timestamp).then((inserts) => {
        for (let i = 0; i < inserts.length; i++)
          inserts[i].timestamp = parseInt(inserts[i].timestamp);
        this.inserts = this.inserts.concat(inserts);
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.updateInterval = setTimeout(this.update, 2000);
      });
    },
    timelapse() {
      this.now = new Date().getTime() - 5000;
      this.nowInterval = setTimeout(this.timelapse, 100);
    }
  }
};
</script>

<style scoped>
#phase {
  color: #336799;
  font-weight: 500;
  font-size: 20px;
  padding: 6px;
}

.standard-preview-container {
  margin: 8px;
}

@media screen and (max-width: 420px) {
  .standard-preview-container {
    transform: scale(0.75);
  }
}
</style>
