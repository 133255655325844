<template>
  <div
      :class="{'p-standard': $props.type === 'standard', 'p-fullscreen' : $props.type === 'fullscreen', 'p-none': $props.type === 'none'}">

    <!-- Name -->
    <div id="name">
      {{ $props.name + ($props.time > 0 && $props.step === 'recall' ? ' (' + $t('group.end') + ')' : '') }}
    </div>

    <!-- Background -->
    <div id="background" v-if="$props.type === 'fullscreen'"></div>

    <!-- Board -->
    <div id="board">
      <div class="row" v-for="row in rows" :key="row.number">
        <div class="row-number">{{ row.number }}.</div>

        <!-- Dates -->

        <div v-if="event === 'dates' && $props.step.startsWith('memo')"
             class="d-flex flex-row flex-nowrap justify-start align-center"
             :class="{'highlight': $props.highlight.includes(row.number - 1)}">
          <div class="field-input border-default" style="width: 55px">
            {{ row.value[0] }}
          </div>
          <div class="field-info" style="width: 285px">
            {{ row.value[1] }}
          </div>
        </div>
        <div v-if="event === 'dates' && !$props.step.startsWith('memo')"
             class="d-flex flex-row flex-nowrap justify-start align-center"
             :class="{'highlight': $props.highlight.includes(row.number - 1)}">
          <div class="field-input" style="width: 55px"
               :class="[row.value[0] === row.value[2] ? 'border-ok' : 'border-not']">
            {{ row.value[2] }}
          </div>
          <div class="field-input border-default" style="width: 55px">
            {{ row.value[0] }}
          </div>
          <div class="field-info" style="width: 230px">
            {{ row.value[1] }}
          </div>
        </div>

        <!-- Images -->

        <div v-if="event === 'images'" class="d-flex flex-row flex-nowrap justify-start align-center">
          <div v-for="(value, index) in row.values" :key="row.number + '-' + index"
               :class="{'highlight': $props.highlight.includes((row.number - 1) * 5 + index)}">
            <v-img class="field-image border-default" :src="$url + '/uploads/images/' + value[0] + '.jpg'"/>
            <v-img class="field-image" :src="$url + '/uploads/images/' + value[1] + '.jpg'"
                   v-if="!$props.step.startsWith('memo')"
                   :class="[value[0] === value[1] ? 'border-ok' : 'border-not']"/>
          </div>
        </div>

        <!-- Imaginary words -->

        <div v-if="event === 'imaginary-words' && $props.step.startsWith('memo')"
             class="d-flex flex-row flex-nowrap justify-start align-center"
             :class="{'highlight': $props.highlight.includes(row.number - 1)}">
          <div class="field-input border-default" style="width: 170px">
            {{ row.value[0] }}
          </div>
          <div class="field-info" style="width: 170px">
            {{ row.value[1] }}
          </div>
        </div>
        <div v-if="event === 'imaginary-words' && !$props.step.startsWith('memo')"
             class="d-flex flex-row flex-nowrap justify-start align-center"
             :class="{'highlight': $props.highlight.includes(row.number - 1)}">
          <div class="field-input" style="width: 110px"
               :class="[$api.wordsMatch(row.value[0], row.value[2]) ? 'border-ok' : 'border-not']">
            {{ row.value[2] }}
          </div>
          <div class="field-input border-default" style="width: 110px">
            {{ row.value[0] }}
          </div>
          <div class="field-info" style="width: 110px">
            {{ row.value[1] }}
          </div>
        </div>

        <!-- Names -->

        <div v-if="event === 'names'" class="d-flex flex-row flex-nowrap justify-start align-center">
          <div v-for="(value, index) in row.values" :key="row.number + '-' + index"
               :class="{'highlight': $props.highlight.includes((row.number - 1) * 3 + index)}">
            <div class="field-input border-default" style="width: 110px">
              {{ value[2] }}
            </div>
            <v-img class="field-face border-default"
                   :src="$url + '/uploads/faces/' + value[0] + '/' + value[1] + '.jpg'"/>
            <div class="field-input" style="width: 110px" v-if="!$props.step.startsWith('memo')"
                 :class="[$api.wordsMatch(value[2], value[3]) ? 'border-ok' : 'border-not']">
              {{ value[3] }}
            </div>
          </div>
        </div>

        <!-- Numbers -->

        <div v-if="event === 'numbers'" class="d-flex flex-row flex-nowrap justify-start align-center">
          <div v-for="(value, index) in row.values" :key="row.number + '-' + index"
               :class="{'highlight': $props.highlight.includes((row.number - 1) * 12 + index)}">
            <div class="field-number border-default" style="width: 24px">
              {{ value[0] }}
            </div>
            <div class="field-number" style="width: 24px" v-if="!$props.step.startsWith('memo')"
                 :class="[value[0] === parseInt(value[1]) ? 'border-ok' : 'border-not']">
              {{ value[1] }}
            </div>
          </div>
        </div>

        <!-- Words -->

        <div v-if="event === 'words'" class="d-flex flex-row flex-nowrap justify-start align-center"
             :class="{'highlight': $props.highlight.includes(row.number - 1)}">
          <div class="field-input" style="width: 170px" v-if="!$props.step.startsWith('memo')"
               :class="[$api.wordsMatch(row.value[0], row.value[1]) ? 'border-ok' : 'border-not']">
            {{ row.value[1] }}
          </div>
          <div class="field-input border-default" style="width: 170px">
            {{ row.value[0] }}
          </div>
        </div>

      </div>
    </div>

    <!-- Score -->
    <div id="score" v-if="!$props.step.startsWith('memo')">{{ score }}</div>

    <!-- Time -->
    <div id="time" v-if="$props.time !== 0">{{ result }}</div>

  </div>
</template>

<script>
export default {
  name: 'GamePreview',
  props: {
    type: {type: String, required: true},
    event: {type: String, required: true},
    memo: {type: Array, required: true},
    recall: {type: Array, required: true},
    order: {type: Array, required: true},
    highlight: {type: Array, required: true},
    name: {type: String, required: true},
    time: {type: Number, required: true},
    step: {type: String, required: true}
  },
  computed: {
    result() {
      return this.parseTime(this.$props.time);
    },
    score() {
      if (this.$props.event === 'dates') {
        return this.$props.recall.filter((item, i) => item === this.$props.memo[this.$props.order[i]].split('.')[0]).length + ' / ' + this.$props.memo.length;
      } else if (this.$props.event === 'images') {
        return this.$props.recall.filter((item, i) => item === this.$props.memo[i]).length + ' / ' + this.$props.memo.length;
      } else if (this.$props.event === 'imaginary-words') {
        return this.$props.recall.filter((item, i) => this.$api.wordsMatch(item, this.$props.memo[this.$props.order[i]].split('.')[0])).length + ' / ' + this.$props.memo.length;
      } else if (this.$props.event === 'names') {
        return this.$props.recall.filter((item, i) => this.$api.wordsMatch(this.$props.memo[this.$props.order[i]].split('.')[2], item)).length + ' / ' + this.$props.memo.length;
      } else if (this.$props.event === 'numbers') {
        let score = 0;
        for (let i = 0; i < this.$props.memo.length; i += 12) {
          let ok = 0, not = 0;
          for (let j = 0; j < 12 && i + j < this.$props.memo.length; j++) {
            if (parseInt(this.$props.recall[i + j]) === this.$props.memo[i + j]) ok++;
            else if (this.$props.recall[i + j] !== '') not++;
          }
          if (not === 0 || ok >= 6) score += ok;
        }
        return score + ' / ' + this.$props.memo.length;
      } else if (this.$props.event === 'words') {
        return this.$props.recall.filter((item, i) => this.$api.wordsMatch(item, this.$props.memo[i])).length + ' / ' + this.$props.memo.length;
      } else return '0 / 0';
    },
    rows() {
      let rows = [];

      if (this.$props.event === 'dates') {
        if (this.$props.step.startsWith('memo')) {
          for (let i = 0; i < this.$props.memo.length; i++) rows.push({
            number: i + 1,
            value: [this.$props.memo[i].split('.')[0], this.$props.memo[i].split('.')[1], this.$props.recall[i]]
          });
        } else {
          for (let i = 0; i < this.$props.memo.length; i++) rows.push({
            number: i + 1,
            value: [this.$props.memo[this.$props.order[i]].split('.')[0], this.$props.memo[this.$props.order[i]].split('.')[1], this.$props.recall[i]]
          });
        }
      }

      if (this.$props.event === 'images') {
        for (let i = 0; i < this.$props.memo.length; i++) {
          if (i % 5 === 0) rows.push({number: i / 5 + 1, values: []});
          rows[rows.length - 1].values.push([this.$props.memo[i], this.$props.recall[i]]);
        }
      }

      if (this.$props.event === 'imaginary-words') {
        if (this.$props.step.startsWith('memo')) {
          for (let i = 0; i < this.$props.memo.length; i++) rows.push({
            number: i + 1,
            value: [this.$props.memo[i].split('.')[0], this.$props.memo[i].split('.')[1], this.$props.recall[i]]
          });
        } else {
          for (let i = 0; i < this.$props.memo.length; i++) rows.push({
            number: i + 1,
            value: [this.$props.memo[this.$props.order[i]].split('.')[0], this.$props.memo[this.$props.order[i]].split('.')[1], this.$props.recall[i]]
          });
        }
      }

      if (this.$props.event === 'names') {
        for (let i = 0; i < this.$props.memo.length; i++) {
          if (i % 3 === 0) rows.push({number: i / 3 + 1, values: []});
          if (this.$props.step.startsWith('memo')) rows[rows.length - 1].values.push([
            this.$props.memo[i].split('.')[0], this.$props.memo[i].split('.')[1],
            this.$props.memo[i].split('.')[2], this.$props.recall[i]
          ]);
          else rows[rows.length - 1].values.push([
            this.$props.memo[this.$props.order[i]].split('.')[0], this.$props.memo[this.$props.order[i]].split('.')[1],
            this.$props.memo[this.$props.order[i]].split('.')[2], this.$props.recall[i]
          ]);
        }
      }

      if (this.$props.event === 'numbers') {
        for (let i = 0; i < this.$props.memo.length; i++) {
          if (i % 12 === 0) rows.push({number: i / 12 + 1, values: []});
          rows[rows.length - 1].values.push([this.$props.memo[i], this.$props.recall[i]]);
        }
      }

      if (this.$props.event === 'words') {
        for (let i = 0; i < this.$props.memo.length; i++)
          rows.push({number: i + 1, value: [this.$props.memo[i], this.$props.recall[i]]});
      }

      return rows;
    }
  },
  watch: {
    highlight() {
      let highlight = document.querySelector('.highlight');
      if (highlight) highlight.scrollIntoView({behavior: 'smooth', block: 'center'});
      else document.querySelector('#board').scrollTo(0, 0);
    },
    time() {
      if (this.$props.time <= 0) return;
      let highlight = document.querySelector('.highlight');
      if (highlight) highlight.scrollIntoView({behavior: 'smooth', block: 'center'});
      else document.querySelector('#board').scrollTo(0, 0);
    }
  },
  mounted() {
    let highlight = document.querySelector('.highlight');
    if (highlight) highlight.scrollIntoView({behavior: 'smooth', block: 'center'});
    else document.querySelector('#board').scrollTo(0, 0);
  },
  methods: {
    parseTime(time) {
      if (time < 0) time = -time;
      let m = Math.floor(time / 6000);
      let s = Math.floor((time % 6000) / 100);
      let ms = time % 100;
      if (m !== 0) return m + ':' + s.toString().padStart(2, '0') + '.' + ms.toString().padStart(2, '0');
      return s + '.' + ms.toString().padStart(2, '0');
    }
  }
};
</script>

<style scoped>

/* general */

.p-standard {
  border: 2px solid #336799;
  border-radius: 6px;
  cursor: pointer;
  height: 304px;
  position: relative;
  width: 404px;
}

.p-standard #name {
  color: #ffffff;
  background-color: #336799;
  font-size: 20px;
  height: 50px;
  padding: 8px;
  text-align: center;
  user-select: none;
  width: 100%;
}

.p-standard #board {
  background-color: #e0d8d5;
  max-height: 250px;
  overflow: hidden;
  user-select: none;
  width: 400px;
}

.p-standard #score {
  background-color: #336799;
  border-top-right-radius: 6px;
  bottom: 0;
  color: #ffffff;
  height: 40px;
  left: 0;
  padding: 10px;
  position: absolute;
  user-select: none;
}

.p-standard #time {
  background-color: #336799;
  border-top-left-radius: 6px;
  bottom: 0;
  color: #ffffff;
  height: 40px;
  padding: 10px;
  position: absolute;
  right: 0;
  user-select: none;
}

.p-fullscreen {
  cursor: pointer;
  height: 100vh;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
}

.p-fullscreen #name {
  color: #ffffff;
  background-color: #336799;
  font-size: 20px;
  height: 30px;
  position: fixed;
  text-align: center;
  top: 0;
  user-select: none;
  width: 100vw;
  z-index: 10;
}

.p-fullscreen #board {
  height: calc(100vh - 30px);
  left: calc(50vw - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px 20px 12px 0;
  position: fixed;
  top: 30px;
  user-select: none;
  width: 420px;
  z-index: 9;
}

.p-fullscreen #score {
  background-color: #336799;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  color: #ffffff;
  height: 40px;
  left: 0;
  padding: 10px 10px 10px 8px;
  position: fixed;
  top: 40px;
  user-select: none;
  z-index: 10;
}

.p-fullscreen #time {
  background-color: #336799;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  color: #ffffff;
  height: 40px;
  left: 0;
  padding: 10px 10px 10px 8px;
  position: fixed;
  top: 90px;
  user-select: none;
  z-index: 10;
}

@media screen and (max-width: 420px) {
  .p-fullscreen #board {
    width: 360px;
  }

  .p-fullscreen #board .row {
    transform: scale(0.76);
  }

  .p-fullscreen #score, .p-fullscreen #time {
    padding: 6px 6px 6px 4px;
    height: 33px;
    font-size: 14px;
  }
}

.p-none {
  display: none;
}

#background {
  background-color: #e0d8d5;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 5;
}

.highlight {
  background-color: #89bbee;
}

.border-ok {
  border: 2px solid #4caf50;
}

.border-not {
  border: 2px solid #e53935;
}

.border-default {
  border: 2px solid #336799;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  margin: 0;
  align-items: center;
  width: 400px;
}

.row-number {
  color: #336799;
  font-size: 18px;
  font-weight: 500;
  padding: 2px;
  text-align: right;
  width: 40px;
}

.field-input {
  background-color: #ffffff;
  border-radius: 8px;
  height: 36px;
  margin: 5px;
  padding: 5px;
  text-align: center;
}

.field-number {
  background-color: #ffffff;
  border-radius: 8px;
  height: 29px;
  margin: 3px;
  padding: 2px;
  text-align: center;
}

.field-image {
  border-radius: 8px;
  height: 62px;
  margin: 5px;
  width: 62px;
}

.field-face {
  border-radius: 8px;
  height: 110px;
  margin: 5px;
  width: 110px;
}

.field-info {
  height: 36px;
  line-height: 0.9;
  margin: 5px;
  padding: 10px 5px;
  text-align: left;
}
</style>
